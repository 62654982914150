import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { getPermissions, updatePermissions } from 'api/permission/permissionRequests';
import { OBTableCell, OBTableRow } from 'components/common/Table/Table.styled';
import { useSnackbar } from 'notistack';
import MainTable from 'components/common/Table';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

export const ProfilesTable = () => {
  const { t } = useTranslation()
  const selectedConfig = JSON.parse(sessionStorage.getItem('configurationData'));
  const PAYLOAD = {
    Tenant_Id: selectedConfig.TenantId,
  };
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { data, mutate } = useSWR('PermissionsByRol', () => getPermissions(PAYLOAD), {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const columns = [
    { id: 'RoleName', label: t("profile_management_profiles_header_profle"), minWidth: 170 },
    { id: 'PermissionGroupDescription', label: t('profile_management_profiles_header_grouper'), minWidth: 170 },
    { id: 'Actualizar', label: 'Actualizar', isCheckbox: true, maxWidth: 170, textAlign: 'center' },
    { id: 'Cargar', label: t("profile_management_profiles_header_load"), isCheckbox: true, maxWidth: 170, textAlign: 'center' },
    { id: 'Descargar', label: t("profile_management_profiles_header_download"), isCheckbox: true, maxWidth: 170, textAlign: 'center' },
    { id: 'Eliminar', label: t("profile_management_profiles_header_delete"), isCheckbox: true, maxWidth: 170, textAlign: 'center' },
    { id: 'Visualizar', label: t("profile_management_profiles_header_visualize"), isCheckbox: true, maxWidth: 170, textAlign: 'center' },
  ];

  const rows = data?.PermissionGroups;

  const handleUpdate = async (value, rowInfo, permissionInfo) => {
    try {
      setLoading(true);
      const PAYLOAD = {
        PermissionGroupName: rowInfo.PermissionGroupName,
        PermissionName: permissionInfo.Name,
        Role_Id: rowInfo.Role_Id,
        Tenant_Id: rowInfo.Tenant_Id,
        Value: value ? '1' : '0',
      };
      await updatePermissions(PAYLOAD);
      await mutate();
      enqueueSnackbar(t("alert_updated_permission"), { variant: 'success' });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const render = (row) => (
    <OBTableRow hover role="checkbox" loading={loading.toString()}>
      <OBTableCell>{row.RoleName}</OBTableCell>
      <OBTableCell>{row.PermissionGroupDescription}</OBTableCell>
      {row.Permissions.map((permission) => (
        <OBTableCell>
          <Checkbox
            disabled={loading}
            checked={permission.Status === '1'}
            onChange={({ target }) => handleUpdate(target.checked, row, permission)}
          />
        </OBTableCell>
      ))}
    </OBTableRow>
  );

  return <MainTable rows={rows} columns={columns} render={render} loading={loading} rowPerPage={8} />;
};
