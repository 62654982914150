import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Box, useTheme } from "@mui/material";
import Parser from "html-react-parser";
import { makeStyles } from "@mui/styles";

// import {
//     mainStyles,//Estílos genéricos
//     theme,
// } from '../../styles'//Se importan los estilos genéricos
import { fileActions, workFlowActions } from "../../store/actions"; //Acciones para redux
import { UseImage, CustomLoadingOverlay } from "../";
import ic_informativo_vector from "../../assets/ic_informativo_vector.svg";
import ic_aceptacion from "../../assets/ic_aceptacion.svg";
import ic_payment_registration from "../../assets/ic_payment_registration.jpg";
import ic_register_validation from "../../assets/ic_register_validation.jpg";
import ic_registroNoEncontrado from "../../assets/ic_registroNoEncontrado.jpg";
import ic_nuevos_usuarios from "../../assets/ic_nuevos_usuarios.jpg";
import ic_registroEncontrado from "../../assets/ic_registroEncontrado.jpg";
import ic_person_conference from "../../assets/ic_person_conference.jpg";
import ic_info_information from "../../assets/ic_pantalla_informativa.jpg";
import ic_info_check_vendor from "../../assets/ic_info_check_vendor.jpg";
import { useTranslation } from "react-i18next";

export const InformationalMessageComponent = (props) => {
  const theme = useTheme();
  const { useState, useEffect } = React; //Hook para el state
  const navigate = useNavigate();
  const [loadView, setLoadView] = useState(false); //Se muestra/oculta el gif loader
  const localStyles = useClasses();

  //Estados de componente
  const [titleText, setTitleText] = useState("");
  const [textDescription, setDescription] = useState("");
  const [iconRefValue, setIconRefValue] = useState("");
  const [continueAction, setContinueAction] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(20);
  const [fileId, setFileId] = useState(0);
  const [goToDashboard, setGoToDashboard] = useState(false);
  const [performLogout, setPerformLogout] = useState(false);
  const [activateContinueButton, setActivateContinueButton] = useState(false);

  const [statusFileInfo, setStatusFileInfo] = useState("");
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");

  //Redux hooks
  const continueWF = useSelector((state) => state.ContinueWorkFlowData);
  const infoFile = useSelector((state) => state.FileInfo.items);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  //Promesa general Init
  useEffect(() => {
    console.log("Data props info message: ", infoFile);
    if (infoFile !== undefined && infoFile !== null) {
      var title = infoFile.Stage.Properties.find(
        (element) => element.Name === "TitleData"
      );
      var description = infoFile.Stage.Properties.find(
        (element) => element.Name === "TextData"
      );
      var iconRef = infoFile.Stage.Properties.find(
        (element) => element.Name === "IconRef"
      );
      var ativateContButton = infoFile.Stage.Properties.find(
        (element) => element.Name === "ActivateContinueButton"
      );
      var goToDashboardParam = infoFile.Stage.Properties.find(
        (element) => element.Name === "GoToDashboard"
      );
      var goPerformLogoutParam = infoFile.Stage.Properties.find(
        (element) => element.Name === "PerformLogout"
      );
      setFileId(
        infoFile.Result === undefined ? infoFile.Id : infoFile.Result.Id
      );
      setTitleText(title?.Value ?? infoFile?.Stage?.Description ?? "");
      setDescription(description?.Value ?? infoFile?.Stage?.FlowState);
      setIconRefValue(
        iconRef?.Value === "ic_gif_folder"
          ? iconRef?.Value?.toString()?.concat(".gif")
          : iconRef?.Value?.toString()?.concat(".jpg")
      );
      setTimerSeconds(
        ativateContButton?.Value == undefined ||
          ativateContButton?.Value == false
          ? 25
          : -1
      );
      setGoToDashboard(goToDashboardParam?.Value === "1" ? true : false);
      setPerformLogout(goPerformLogoutParam?.Value === "1" ? true : false);
      setActivateContinueButton(
        ativateContButton?.Value === "1" ? true : false
      );
    }
  }, [infoFile]);

  useEffect(() => {
    if (timerSeconds == -4000000000000) {
      console.log("--------------Timer ejecutado--------------");
      setTimerSeconds(25);
      console.log("---Peticion 3------- FileInfo");
      dispatch(
        fileActions.GetFileInfo({
          FileId: fileId,
          WorkFlowInstanceId: workFlowInstanceId,
        })
      );
    }
    // salir cuando lleguemos a 0
    if (!timerSeconds) return;

    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds - 1);
    }, 1000);

    // clear interval al volver a renderizar para evitar pérdidas de memoria
    return () => clearInterval(intervalId);
  }, [timerSeconds]);

  //Obtener valores de persistencia de datos y mostrarlos en interfa
  useEffect(() => {
    setLoadView(continueWF.loading);
    console.log(
      "-------------response continue EF-----------------. ",
      continueAction,
      continueWF.items
    );
    if (
      continueWF.items !== undefined &&
      continueWF.items !== null &&
      continueAction
    ) {
      console.log("response continue EF. ", continueWF.items);
      if (goToDashboard && continueWF.items.Result === true) {
        console.log(
          "****************----------Retorna a pagina de inicio------************"
        );
        navigate("/inbox/homeComponent");
        props?.refresh();
      } else if (performLogout && continueWF.items.Result === true) {
        console.log(
          "****************----------Retorna a pagina de inicio---paso 2---************"
        );
        navigate("/inbox/homeComponent");
        props?.refresh();
      } else if (continueWF.items.Result === true) {
        props.actionResset();
        props?.refresh();
      }
    }
  }, [continueWF, continueWF.loading]);

  let handleContinue = () => {
    setContinueAction(true);
    let dataRequest = {
      File_Id: fileId,
      WorkFlowInstanceId: workFlowInstanceId,
    };
    dispatch(workFlowActions.ContinueWorkFlow(dataRequest));
    // props?.refresh();
  };

  const getImageName = () => {
    if (
      titleText === "Validación de documentos" &&
      iconRefValue === "ic_informativo_vector.jpg"
    ) {
      return ic_informativo_vector;
    } else if (iconRefValue === "ic_aceptacion.jpg") {
      return ic_aceptacion;
    } else if (
      statusFileInfo === "Visita finalizada" &&
      iconRefValue === "ic_payment_registration.jpg"
    ) {
      return ic_payment_registration;
    } else if (statusFileInfo === "Visita finalizada") {
      return ic_register_validation;
    } else if (iconRefValue === "ic_registroNoEncontrado.jpg") {
      return ic_registroNoEncontrado;
    } else if (iconRefValue === "ic_nuevos_usuarios.jpg") {
      return ic_nuevos_usuarios;
    } else if (iconRefValue === "ic_registroEncontrado.jpg") {
      return ic_registroEncontrado;
    } else if (iconRefValue === "ic_person_conference.svg") {
      return ic_person_conference;
    } else if (iconRefValue === "ic_info_information.jpg") {
      return ic_info_information;
    } else if (iconRefValue === "ic_info_check_vendor.jpg") {
      return ic_info_check_vendor;
    } else return ic_info_information;
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        overflow: "hidden",
        textAlign: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "55vh",
        ["@media only screen and (max-width: 600px)"]: {
          height: "40vh",
        },
      }}
    >
      <Box
        sx={{
          width: "70%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          overflow: "hidden",
          textAlign: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "45vh",
        }}
      >
        <Typography
          gutterBottom
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "28px",
            color: theme.palette.userStyle.textDetails,
          }}
        >
          {titleText === "Mensaje de asignación a Candidato"
            ? t("informational_message_reister_completed")
            : titleText}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: "25vh",
            alignContent: "center",
            alignItems: "center",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            ["@media only screen and (max-width: 600px)"]: {
              height: "38vh",
            },
            ["@media only screen and (max-height: 800px)"]: {
              height: "20vh",
            },
            margin: ".5em 0.5em",
          }}
        >
          {iconRefValue !== undefined &&
          iconRefValue !== null &&
          iconRefValue !== "" ? (
            <UseImage
              className={localStyles.imgHelp}
              src={getImageName()}
              type="Image"
            />
          ) : (
            <UseImage
              className={localStyles.imgHelp}
              src={getImageName()}
              type="Image"
            />
          )}
        </Box>
        <Typography
          variant="h6"
          style={{
            marginTop: "0.3em",
            marginBottom: ".8em",
            color: theme.palette.userStyle.textDetails,
          }}
          gutterBottom
        >
          {textDescription !== undefined && textDescription !== ""
            ? Parser(textDescription)
            : ""}
        </Typography>
      </Box>
      {activateContinueButton ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            id="btnInfoReqAction"
            fullWidth
            variant="contained"
            sx={{ height: 45, px: 10, minWidth: 220, maxWidth: 350 }}
            onClick={() => {
              handleContinue();
            }}
          >
            {statusFileInfo === "Visita finalizada" ? "Finalizar" : "Continuar"}
          </Button>
        </Box>
      ) : null}
      {loadView ? (
        <CustomLoadingOverlay
          active={loadView}
          text={t("general_loading_message")}
        />
      ) : null}
    </Box>
  );
};

const useClasses = makeStyles((theme) => ({
  imgHelp: {
    //Help
    height: "25vh",
    width: "50% important",
    ["@media only screen and (max-width: 812px)"]: {
      width: "50% important",
      height: "25vh",
    },
    ["@media only screen and (max-height: 800px)"]: {
      height: "15vh",
    },
  },
}));
