//Peticiones HTTP para temas referentes a Login y datos de usuario
import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';
import { BodySecurityResponseForceEncripted, ReferCredentials } from '../utils'

export const authRequests = {}//Objeto agrupador de funciones

authRequests.Authentication = (data) => {//Función de autenticación
  return axios.post(
    AppUrls.Authentication,//url
    Config.SetParameters(data, '', '', ''),//Objeto a enviar al API
    Config.ConfigNoAuth()//headers
  ).then(handleResponse)//next => actions => AuthActions
    .then(userAuthenticate => {
      sessionStorage.clear()
      sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
        'tokenMc',
        userAuthenticate.Body.Token
      )
      /* sessionStorage.setItem(//Borrar al cerrar sesión
        'userName',
        `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName}`
      ) */
      sessionStorage.setItem(//Borrar al cerrar sesión
        'Rol',
        `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
      )
      selectAvatar(userAuthenticate.Body.UserLoginData?.Metadata)

      return userAuthenticate;
    });
}

authRequests.AuthenticationEncripted = (data) => {
  // console.log("Request: ", data)
  return axios.post(
    AppUrls.Authentication,
    Config.SetParameters(data, "", "", "", true),
    Config.ConfigNoAuth()//headers
  ).then(handleResponseForceEncripted)
    .then(userAuthenticate => {
      console.log('userAuthenticate', userAuthenticate)
      // console.log("userAuthenticate: response: ", userAuthenticate)
      sessionStorage.clear()
      sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
        'tokenMc',
        userAuthenticate.Body.Token
      )
      /* sessionStorage.setItem(//Borrar al cerrar sesión
        'userName',
        `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName} ${userAuthenticate.Body.UserLoginData.MotherLastName}`
      ) */
      // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
      sessionStorage.setItem(//Borrar al cerrar sesión
        'Rol',
        `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
      )

      selectAvatar(userAuthenticate.Body.UserLoginData?.Metadata)
      

      return userAuthenticate;
    });
}

authRequests.AuthenticatorGeneraCredential = (token, tokenSR) => {
  console.log('AuthenticatorGeneraCredential_token', token)
  console.log('AuthenticatorGeneraCredential_tokenSR', tokenSR)
  console.log('ReferCredentials', ReferCredentials, AppUrls.AuthenticatorGeneraCredential + "?token=" + token + "&tokenSR=" + tokenSR + "&refer=" + ReferCredentials);
  return axios.post(
    AppUrls.AuthenticatorGeneraCredential + "?token=" + token + "&tokenSR=" + tokenSR + "&refer=" + ReferCredentials,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
      },
    }
  ).then((response) => {
    console.log('AuthenticatorGeneraCredential', response)
    return response.data;
  })
}

authRequests.SSOAuthenticate = (data) => {//Función de autenticación
  return axios.post(
    AppUrls.SSOAuthenticate,//url
    Config.SetParameters(data, '', '', '', true),//Objeto a enviar al API
    Config.ConfigNoAuth()//headers
  ).then(handleResponseForceEncripted)//next => actions => AuthActions
    .then(userAuthenticate => {
      console.log('userAuthenticate', userAuthenticate)

      //verificar que tenga roles de MesaControl
      const users = userAuthenticate.Body.filter((res) => res.UserLoginData.SecurityLoginData.Roles.some(rol => rol.Application === "MesaControl"))
      console.log('userAuthenticateUsersMC', users)
      if (!users.length) throw new Error("No se encontraron usuarios asociados a esta cuenta");

      if (users.length > 1) {
        return { ...userAuthenticate, Body: users[0], roles: users };
      } else {
        // console.log("userAuthenticate: response: ", userAuthenticate)
        sessionStorage.clear()
        sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
          'tokenMc',
          users[0].Token
        )
        sessionStorage.setItem(//Borrar al cerrar sesión
          'userName',
          `${users[0].UserLoginData.Name} ${users[0].UserLoginData.FatherLastName} ${users[0].UserLoginData.MotherLastName}`
        )
        // console.log("Role: ", users[0].UserLoginData.SecurityLoginData.Roles[0].Name)
        sessionStorage.setItem(//Borrar al cerrar sesión
          'Rol',
          `${users[0].UserLoginData.SecurityLoginData.Roles[0]?.Name}`
        )

        selectAvatar(userAuthenticate.Body.UserLoginData?.Metadata)

        return { ...userAuthenticate, Body: users[0] };
      }
    });
}

authRequests.RevokeUserTokens = (data) => {
  return axios.post(
    AppUrls.Disabletoken,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse)
}

//desencripta y devuelve objeto de la respuesta del API
let handleResponse = (response) => {
  console.log("DISABLE TOKEN RESPONSE", response)
  let respObject = response?.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}

let handleResponseForceEncripted = (response) => {
  let respObject = BodySecurityResponseForceEncripted(response.data);
  if (!respObject.IsOK) {
    // console.log("respObject:handleResponseForceEncripted ", respObject)
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}

const selectAvatar = (metadata) => {
  const picture = metadata?.find(met => met.Name === 'Picture')?.Value
  if (picture) {
    sessionStorage.setItem("avatar", picture);
  } else {
    console.log('Entro aqui env:',process.env.REACT_APP_ENVIRONMENT)
    sessionStorage.setItem("avatar", process.env.REACT_APP_ENVIRONMENT ?? "undefined");
  }
}