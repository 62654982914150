import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { fileActions, workFlowActions } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NoActionsPending } from "components/Alerts/NoActionsPending";
import { GenericHeaderActions, UseImage } from "components";
import { ActionsFormDynamic } from "components/dynamic/ActionsFormDynamic";
import { InformationalMessageComponent } from "components/InformationalMessage/InformationalMessageComponent";
import IdentityValidation from "components/LEGOS/IdentityValidation";
import Sign from "components/LEGOS/Sign";
import InformationStage from "components/LEGOS/InformationStage";
import { UploadRequiredDocuments } from "components/document/UploadRequiredDocuments";
import { DocumentApproval } from "components/document/DocumentApproval/DocumentApproval";
import { mainStyles } from "../../../theme/mainStyles";
import { useTranslation } from "react-i18next";
import noDataToShow from "../../../assets/noDataToShow.svg";
import IndexHiringKits from "pages/HiringKits/indexHiringKits";

export const ActionsTab = () => {
  const dispatch = useDispatch();
  const noInfo = mainStyles.noDataSections();
  const [stageNameTemp, setStageNameTemp] = useState("Resumen")
  const { id } = useParams();
  const { t } = useTranslation();
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");
  const infoFile = useSelector((state) => state.FileInfo.items);
  const returnStageData = useSelector((state) => state.ReturnStageData);
  const stageName = infoFile?.Stage?.Name;

  const userFuntions = useSelector(
    (state) =>
      state.Authentication.items?.UserLoginData?.SecurityLoginData?.Applications[0]?.Modules.find(
        (md) => md.Id === 2,
      )?.Functions,
  );

  const getInfoDispatch = () => {
    dispatch(
      fileActions.GetFileInfo({
        FileId: id,
        WorkFlowInstanceId: workFlowInstanceId,
      }),
    );
  };

  const RENDER_COMPONENT_BY_STAGE = {
    "Carga documental": <UploadRequiredDocuments onRefresh={getInfoDispatch} />,
    "Cuestionario dinamico": <ActionsFormDynamic onRefresh={getInfoDispatch} />,
    "Información de la Solicitud": (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    Notificación: <InformationalMessageComponent refresh={getInfoDispatch} />,
    "Generación de documentos": (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    "Generación de documentos Selección": (
      <IndexHiringKits dataStage={infoFile} />
    ),
    AsignacionMetadatos: (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    RegisterDocumentMatches: (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    "Finalizar Paralelo": (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    AsignarSolicitud: (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    "Cancelar Paralelos": (
      <InformationalMessageComponent refresh={getInfoDispatch} />
    ),
    "Validación Documental": <DocumentApproval onRefresh={getInfoDispatch} />,
    Resumen: <NoActionsPending />,
    "Firma de documentos": <Sign />,
    "Validación de identidad": <IdentityValidation />,

    "Validación de minucias": (
      <InformationStage
        title="Captura de Huellas Digitales"
        description="Por favor, procede a capturar tus huellas digitales utilizando un dispositivo móvil."
      />
    ),
    Nom151: (
      <InformationStage
        title="Generando NOM 151"
        description="Estamos generando la NOM 151. Por favor, espera un momento."
      />
    ),
  };

  const stagesToCheck = [
    "Notificación",
    "Generación de documentos",
    "AsignacionMetadatos",
    "AsignarSolicitud",
    "RegisterDocumentMatches",
    "Finalizar Paralelo",
  ];

  useEffect(() => {
    const showActionsProp = infoFile?.IsAssignedUser;
    const stageName = showActionsProp ? infoFile?.Stage?.Name : "Resumen";
    setStageNameTemp(stageName)

    if (stagesToCheck.includes(stageName)) {
      const intervalId = setInterval(getInfoDispatch, 7000);
      return () => clearInterval(intervalId);
    }
  }, [infoFile]);

  useEffect(() => {
    if (returnStageData?.items != null) {
      getInfoDispatch();
    }
  }, [returnStageData]);
/* 
  const handleReturnStage = () => {
    dispatch(
      workFlowActions.ReturnStage({ File_Id: id, WorkFlowInstanceId: null }),
    );
  }; */

  return (
    <Box>
      {RENDER_COMPONENT_BY_STAGE[stageNameTemp] ?? (
        <div className={noInfo.noData}>
          <UseImage src={noDataToShow} type="img" />
          <Typography variant="h6" className={noInfo.h6}>
            {t("general_no_data")}
          </Typography>
        </div>
      )}
    </Box>
  );
};
