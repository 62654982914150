import React, { useState } from "react";
import {Box, Button } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const ImageVisor = ({ src }) => {
  const [scale, setScale] = useState(1); // Escala inicial de la imagen

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Zoom máximo x3
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Zoom mínimo x0.5
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      sx={{ padding: 2, height: '80vh' }}
    >
      <Box display="flex" justifyContent="center" gap={3}>
        <Button onClick={handleZoomIn} color="primary" variant="contained">
          <ZoomInIcon />
        </Button>
        <Button onClick={handleZoomOut} color="primary" variant="contained">
          <ZoomOutIcon />
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={src}
          alt="Zoomable"
          style={{
            transform: `scale(${scale})`,
            transition: "transform 0.3s ease",
            objectFit: 'contain',
            maxHeight: '100%j'

          }}
        />
      </Box>
    </Box>
  );
};

export default ImageVisor;
