import React, { useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Typography,
  styled,
} from "@mui/material";
import { Icon } from "@iconify/react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IsDevice } from "utils";
import { SelectLanguage } from "components";
import Avatar1 from "../assets/generalInfo/actor1.svg";
import Avatar2 from "../assets/generalInfo/actor2.svg";
import Avatar3 from "../assets/generalInfo/actor3.svg";
import Avatar4 from "../assets/generalInfo/actor4.svg";
import Avatar5 from "../assets/generalInfo/actor5.svg";
import ArgFlag from "../assets/icons/Argentina.png";
import BraFlag from "../assets/icons/Brazil.png";
import ColFlag from "../assets/icons/Colombia.png";
import ChlFlag from "../assets/icons/Chile.png";
import MexFlag from "../assets/icons/Mexico.png";
import UruFlag from "../assets/icons/Uruguay.png";
//import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const DrawerStyled = styled(Box)(({ theme }) => ({
  width: 290,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const menuItems = (t) => [
  /* {
    name: t("drawer_report"),
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="tabler:report-analytics" />
      </Box>
    ),
    link: "/inbox/dashboards",
  }, */
  {
    name: t("main_assignments"),
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="majesticons:document-award-line" />
      </Box>
    ),
    link: "/inbox/homeComponent",
    secundary: [
      {
        name: "Asignación individual",
        link: "/inbox/homeComponent/single",
      },
      {
        name: "Asignación masiva",
        link: "/inbox/homeComponent/masive",
      },
    ],
  },
  {
    name: "Alta Candidatos",
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="heroicons:users" />
      </Box>
    ),
    link: "/inbox/new-file",
  },
  {
    name: t("general_search"),
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="akar-icons:search" />
      </Box>
    ),
    link: "/inbox/search",
  },
];

export const Drawer = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  //const { instance  } = useMsal();
  //const isAuthenticated = useIsAuthenticated();

  const cerrarSesion = () => {
    console.log("CIERRASESION");
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("tokenMc");
    localStorage.removeItem("versionApp");

    //si esta auth con SSO hace logout con microsoft y redirecciona a login
    /* Comentado por si se necesita esta accion */
    /* if(isAuthenticated){
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
        return
    } */
    navigate("/auth/login");
  };

  const closeDrawer = (item) => {
    navigate(item.link);
    props.onClose();
  };

  const avatarSelected = () => {
    switch (sessionStorage.avatar) {
      case "1":
        return Avatar1;
      case "2":
        return Avatar2;
      case "3":
        return Avatar3;
      case "4":
        return Avatar4;
      case "5":
        return Avatar5;
      case "ARG":
      case "ARGDEV":
        return ArgFlag;
      case "BRA":
      case "BRADEV":
        return BraFlag;
      case "COL":
      case "COLDEV":
        return ColFlag;
      case "CHL":
      case "CHLDEV":
        return ChlFlag;
      case "MEX":
      case "MEXDEV":
        return MexFlag;
      case "URY":
      case "URYDEV":
        return UruFlag;
      default:
        return "/static/images/avatar/2.jpg";
    }
  };

  return (
    <MuiDrawer open={props.open} onClose={props.onClose}>
      <DrawerStyled>
        <Box style={{ p: "30px 20px 5px 20px" }}>
          <Box style={{ pl: 1, textAlign: "center" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                style={{
                  color: "primary.mainLyrics",
                  backgroundColor: "primary.main",
                  fontWeight: 600,
                  width: 100,
                  height: 100,
                  fontSize: 40,
                  mt: 0.7,
                }}
                alt={sessionStorage.userName}
                src={avatarSelected()}
              />
            </Box>
            <Typography variant="subtitle1" style={{ mt: 3 }} color="primary">
              {sessionStorage.userName}
            </Typography>
            <Typography variant="subtitle1" style={{ mt: 2 }}>
              {sessionStorage.Rol}
            </Typography>
          </Box>
          <List>
            {menuItems(t).map((menuItems) =>
              IsMobile && menuItems.name === "Alta Candidatos" ? null : (
                <ListItem
                  button
                  key={menuItems.text}
                  onClick={() => closeDrawer(menuItems)}
                >
                  <ListItemIcon style={{ minWidth: 40 }}>
                    {menuItems.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "secondary.main" }}
                    primary={menuItems.name}
                  />
                </ListItem>
              ),
            )}
            <ListItem sx={{ ml: "1em" }}>
              <SelectLanguage />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ p: "5px 20px 5px 20px", mt: "auto" }}>
          <List>
            <ListItem button onClick={() => cerrarSesion()}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "secondary.main" }}
                primary={t("log_out")}
              />
            </ListItem>
          </List>
        </Box>
      </DrawerStyled>
    </MuiDrawer>
  );
};
