import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { Button, Box, IconButton } from "@mui/material";
// import ErrorIcon from "@mui/icons-material/Error";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

// import { ModalVisual, ActorMVisual } from "../../modals";
import { CustomAlert } from "../../CustomAlert";
import { workFlowActions } from "../../../store/actions";
import { documentRequests } from "../../../services";

// import { RequiredDocumentsSelector } from "./RequiredDocumentsSelector";
// import { UploadMethodSelection } from "./UploadMethodSelection";
// import { UploadRequiredDocumentDragAndDrop } from "./UploadDocumentDragAndDrop";
// import DocumentMetadata from "../DocumentMetadata";
// import { CameraUpload } from "./CameraUpload";
// import { useStyles } from "./TabsStyle";
// import { mainStyles } from "../../theme/mainStyles";
// import { UseImage } from "../../index";

// import ine from "../../assets/id.svg";
// import passport from "../../assets/passport.svg";
// import curp from "../../assets/CURP.svg";
// import comprobante from "../../assets/ComprobanteGen.svg";
// import consentimiento from "../../assets/consentimiento.svg";
// import contrato from "../../assets/contrato.svg";
// import privacidad from "../../assets/privacidad.svg";
// import ingresos from "../../assets/ingresos.svg";
// import nom from "../../assets/NOM.svg";
// import docGenerico from "../../assets/docGenerico.svg";
import NoDocs from "../../../assets/NoDocs.png";

import { DocumentModalViewer } from "./DocumentModalViewer";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconSectionsValidate,
  NoDataContent,
  TitleGroup,
  ImageNotFound,
  TextNoDataInformation,
} from "./DocumentApproval.styled";
import StatusRenderer from "./../DocBasicView/StatusRenderer";
import { useParams } from "react-router-dom";

export const DocumentApproval = ({ onRefresh }) => {
  const {id: FileId} = useParams()
  const infoFile = useSelector((state) => state.FileInfo);
  const wfContinueData = useSelector((state) => state.ContinueWorkFlowData);
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");
  const documentosRechazados = useSelector((state) =>
    state.FileInfo.itemsDocCardValidation?.some((item) =>
      item.documentos?.some((itemDoc) => itemDoc?.validacion === false),
    ),
  );
  const documentosPendientes = useSelector((state) =>
    state.FileInfo.itemsDocCardValidation?.some((item) =>
      item.documentos?.some(
        (itemDoc) => itemDoc?.validacion == null && itemDoc.required == true,
      ),
    ),
  );

  const dispatch = useDispatch();

  const [openFinaliceValidation, setOpenFinaliceValidation] = useState(false);
  const [listDocumentValidation, setListDocumentValidation] = useState([]);
  const [expandedGrouper, setExpandedGrouper] = useState(null)

  const { t } = useTranslation();

  useEffect(() => {
    if (infoFile?.itemsDocCardValidation?.length > 0) {
      setListDocumentValidation(infoFile?.itemsDocCardValidation);
      // addExpandedDocs(infoFile?.itemsDocCardValidation)
    }
  }, [infoFile]);

  const handleFinaliceValidaction = () => {
    const objRequest = {
      File_Id: FileId,
      Status: "Completo",
      Result: documentosRechazados === true ? "Rechazado" : "Completo",
      WorkFlowInstanceId: workFlowInstanceId,
    };

    dispatch(workFlowActions.ContinueWorkFlow(objRequest));
  };

  const handleChange = (panel) => (event, isExpanded) => {

      setExpandedGrouper({ ...expandedGrouper, [panel]: isExpanded });

  }

  useEffect(() => {
    if (wfContinueData.items !== undefined) {
      dispatch(workFlowActions.ContinueWorkFlow(undefined, true));
      onRefresh();
    } else if (wfContinueData.error !== undefined) {
      console.log("wfContinueData.error-------- ", wfContinueData.error);
    }
  }, [wfContinueData]);

  const descargaDocumento = async (idDocumento, name) => {
    try {
      const responseDoc = await documentRequests.GetDocumentZip(idDocumento);
      // Ya que responseDoc es un objeto que contiene la URL del archivo ZIP,
      // simplemente accede a la propiedad 'data' que contiene la URL
      const url = responseDoc.data;
      // Crea un enlace
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.zip`);
      // Haz clic en el enlace para iniciar la descarga
      link.click();
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  return (
    <Box>
      {listDocumentValidation.length > 0 ? (
        listDocumentValidation?.map((card, index) => {
          const documentos = card.documentos || [];
          let status;
          if (
            documentos.some(
              (item) =>
                item?.validacion === undefined || item?.validacion === null,
            )
          ) {
            status = "pending";
          } else if (documentos.some((item) => item?.validacion === true)) {
            status = "approved";
            handleChange(null,`panel${card.grupoDescription}`)
          } else if (documentos.some((item) => item?.validacion === false)) {
            status = "rejected";
            handleChange(null,`panel${card.grupoDescription}`)
          }

          if (![".CER", ".KEY"].includes(card.grupo)) {
            const isExpanded = expandedGrouper?.[`panel${card.grupoDescription}`]/*  || status === "pending" */
            return (
              <Accordion 
              expanded={ isExpanded}
              onChange={handleChange(`panel${card.grupoDescription}`)}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <TitleGroup>{card.grupoDescription}</TitleGroup>
                  <Box sx={{ padding: "1px 0.5em;" }}>
                    {console.log("cardDocx", status,card.grupoDescription)}
                    <StatusRenderer status={status} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {card.documentos?.map((doc) => {
                    // console.log("doc-----------: ", doc);
                    let statusDocument;

                    if (doc.validacion == undefined || doc.validacion == null) {
                      statusDocument = "pending";
                    } else if (doc.validacion) {
                      statusDocument = "approved";
                    } else if (!doc.validacion) {
                      statusDocument = "rejected";
                    }

                    if (
                      ["Firma autógrafa", "Firma autografa"].includes(
                        doc.nombre,
                      ) ||
                      [".xml", ".wsq"].includes(doc.extension)
                    ) {
                      return "";
                    } else if (doc.extension === ".zip") {
                      return (
                        <Box>
                          <IconButton
                            id="btnDownloadDoc"
                            onClick={() =>
                              descargaDocumento(doc.id, doc.nombre)
                            }
                          >
                            <Icon
                              icon="formkit:zip"
                              color="#A4CE4E"
                              fontSize={90}
                            />
                          </IconButton>
                        </Box>
                      );
                    } else {
                      return (
                        <IconSectionsValidate>
                          {console.log(
                            "ITEMSDOCS*******************1*: ",
                            // IsValidate,
                            doc.validacion,
                            doc,
                          )}
                          <DocumentModalViewer
                            docInfo={doc}
                            statusCurrent={statusDocument}
                          />
                          {/* <ModalVisual
                            // docName={doc.nombre}
                            // idDoc={doc.id}
                            // metaData={doc.metaData}
                            // docVal={doc.validacion}
                            // extDocVal={doc.docExtVal}
                            // validation={val}
                            // docsApproved={docsA}
                            // refresh={refresh}
                            // statusVideo={
                            //   bioData.Face?.Selfie?.ComparisonVsVideo
                            //     ?.StatusVideo
                            // }
                            // IsValidate={IsValidate}
                            // fileId={fileId}
                            // extension={doc.extension}
                            // docDescription={doc.Description}
                          /> */}
                        </IconSectionsValidate>
                      );
                    }
                  })}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return "";
          }
        })
      ) : (
        <NoDataContent>
          <ImageNotFound src={NoDocs} />
          <TextNoDataInformation>
            {t("general_no_document")}
          </TextNoDataInformation>
        </NoDataContent>
      )}

      {!documentosPendientes && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1em",
            marginBottom: "0.5em",
          }}
        >
          <Button
            id="btnRequestValidate"
            variant="contained"
            sx={{ height: 45, px: 10, maxWidth: 300 }}
            onClick={() => {
              setOpenFinaliceValidation(true);
            }}
          >
            {t("finalize_validation")}
          </Button>
        </Box>
      )}

      <CustomAlert
        closeAction={() => {
          setOpenFinaliceValidation(false);
        }}
        open={openFinaliceValidation}
        title={t("finalize_validation")}
        body={t("finalize_validation_confirm")}
        leftButtonText={t("general_cancel")}
        leftButtonAction={() => {
          setOpenFinaliceValidation(false);
        }}
        rightButtonText={t("general_accept")}
        rightButtonAction={() => {
          handleFinaliceValidaction();
          setOpenFinaliceValidation(false);
        }}
      />
    </Box>
  );
};
