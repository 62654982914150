import { Box, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { fileRequests } from '../services';
import { UseImage } from './UseImage';
import iconExcel from '../assets/icons/IconExcel.png'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function DowloadSearch({ searching }) {
    const { t } = useTranslation()
    const authInfo = useSelector(state => state.Authentication?.items)

    const [activeDownloadTemplate, setActiveDownloadTemplate] = useState(false);


    const DowloadTemplate = async () => {
        setActiveDownloadTemplate(true)
        const responseDoc = await fileRequests.DownloadFilesBySearchContent({ idUser: authInfo?.UserLoginData?.Id, searching: searching });
        console.log('responseDoc', responseDoc)
        const url = window.URL.createObjectURL(new Blob([responseDoc]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Datos de Busqueda.xlsx");
        document.body.appendChild(link);
        link.click()
        setActiveDownloadTemplate(false)
    }

    return (
        <Box>
            <Tooltip title={t("download_search_download_data")} placement="top">
                <IconButton disabled={activeDownloadTemplate ? true : false} sx={{
                    width: '100%',
                    height: 45,
                    fontSize: 16,
                    fontWeight: 500,
                    mt: .5,
                    maxWidth: 300,
                }} variant='contained' color="primary" onClick={() => DowloadTemplate()}>
                    <UseImage src={iconExcel} alt="iconExcel" width={60} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
