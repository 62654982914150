/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  Avatar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { fileActions } from "../../store/actions";
import { IsDevice, paths, routes } from "../../utils";
import { constPathRoot } from "../../utils/PathPublish";
import { useStyles } from "./HeaderStyles";
import Colombia from "../../assets/icons/Colombia.png";
import Argentina from "../../assets/icons/Argentina.png";
import Chile from "../../assets/icons/Chile.png";
import Uruguay from "../../assets/icons/Uruguay.png";
import Mexico from "../../assets/icons/Mexico.png";
import Brazil from "../../assets/icons/Brazil.png";
import { useTranslation } from "react-i18next";
import { SelectLanguage } from "components";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import useSWR from "swr";
//import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export function Header() {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const { IsMobile } = IsDevice();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [product, setproduct] = useState("");
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [rolUser, setRolUser] = useState("");
  const [initial, setInitial] = useState("");
  const [stageData, setStageData] = useState({});
  const navigate = useNavigate();

  const { t } = useTranslation();

  const infoFile = useSelector((state) => state.FileInfo);
  const auth = useSelector((state) => state.Authentication);
  const { DataReport } = location.state || {};
  const workFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");

  // const { data: newNotifications } = useSWR("UserHasNetNotify", null);
  // const hideNotificationsDot = newNotifications?.NewNotifications === 0;

  const folio = infoFile?.items?.Folio;

  useEffect(() => {
    if (!infoFile.items || infoFile.loading) return;

    setStageData({
      ...stageData,
      folio: infoFile?.items?.Folio,
      currentStage: infoFile?.items?.Stage?.Description,
      fullName: `${infoFile?.items?.Name} ${infoFile?.items?.FatherLastName}`,
    });
  }, [infoFile]);

  useEffect(() => {
    // console.log('Inicitial---- headers', id)
    // console.log("Initial---- window.sessionStorage", sessionStorage)
    if (folio) {
      dispatch(
        fileActions.GetFileInfo({
          FileId: id,
          WorkFlowInstanceId: workFlowInstanceId,
        }),
      );
    }

    let userVal = sessionStorage.getItem("userName");
    if (userVal !== undefined && userVal !== null && userVal !== "") {
      // console.log("Entra en validacion------ ", userVal)
      let nameVal = userVal; //user.split(" ")[0]
      let rolUserVal = sessionStorage.getItem("Rol");
      let initialVal = userVal?.split("")[0];

      setUser(userVal);
      setName(nameVal);
      setRolUser(rolUserVal);
      setInitial(initialVal);
    } else {
      console.log(
        "Entra en constantes de header------ ",
        user,
        name,
        rolUser,
        initial,
      );
    }
  }, []);

  useEffect(() => {
    if (infoFile.items !== undefined) {
      setproduct(infoFile.items.Product);
    }
  }, [infoFile]);

  useEffect(() => {
    if (auth.items !== undefined) {
      // console.log("Datos Login:----------------------------------------------- ", auth.items)
      let userVal = `${auth.items?.UserLoginData?.Name} ${
        auth.items?.UserLoginData?.FatherLastName
      } ${auth.items?.UserLoginData?.MotherLastName ?? ""}`;
      let nameVal = userVal; //user.split(" ")[0]
      let rolUserVal = `${auth.items?.UserLoginData?.SecurityLoginData.Roles[0].Name}`;
      let initialVal = userVal?.split("")[0];
      // console.log("Datos Login:---------------setters-------------------------- ", userVal, nameVal, rolUserVal, initialVal)
      setUser(userVal);
      setName(nameVal);
      setRolUser(rolUserVal);
      setInitial(initialVal);
      // sessionStorage.setItem('userName', userVal)
      // sessionStorage.setItem('Rol', rolUserVal)
    } else if (auth.error !== undefined) {
      // setLoadView(false)
      // alert(auth.error)
      console.log("(auth.error)-----------: ", auth.error);
    }
  }, [auth]);

  let path = window.location.pathname;

  // console.log('pathpath', path)

  // AQUI DEBES DE CONDICIONAR QUE TIENES EN LA URL PARA PODER DAR EL TITULO DE LA MISMA
  const setSectionName = () => {
    console.log("path: ", path);
    switch (path) {
      case `${constPathRoot.pathRoot}/inbox/dashboards`:
        return t("main_dashboard");
      case `${constPathRoot.pathRoot}/inbox/search`:
        return t("search");
      case `${constPathRoot.pathRoot}/inbox/new-file`:
        return t("main_candidates");
      case `${constPathRoot.pathRoot}/inbox/homeComponent`:
        return t("main_assignments");
      case `${constPathRoot.pathRoot}/inbox/homeComponent/single`:
        return "Asignaciones Individuales";
      case `${constPathRoot.pathRoot}/inbox/homeComponent/masive`:
        return "Asignaciones Masivas";
      case `${constPathRoot.pathRoot}/inbox/busqueda/:id`:
        return t("main_assignments");
      case `${constPathRoot.pathRoot}/inbox/bulkUsers`:
        return t("main_candidates_massive");
      case `${constPathRoot.pathRoot}/inbox/bulkDocuments`:
        return t("main_documents");
      case `${constPathRoot.pathRoot}/inbox/uploadDocumentsMasive`:
        return t("main_documents");
      case `${constPathRoot.pathRoot}/inbox/UserManagement`:
        return t("main_admin_users");
      case `${constPathRoot.pathRoot}/inbox/BulkMetadata`:
        return t("main_upload_massive_metadata");
      case `${constPathRoot.pathRoot}/inbox/bulkMetadataUpload`:
        return t("main_upload_massive_metadata");
      case `${constPathRoot.pathRoot}/inbox/inboxReportsFilter`:
        return t("main_reports");
      case `${constPathRoot.pathRoot}/inbox/config`:
        return t("config_header_label");
      case `${constPathRoot.pathRoot}/inbox/profileManagement`:
        return t("profile_management_header_label");
      case `${constPathRoot.pathRoot}/inbox/Charts`:
        return t("main_dashboard");
      case `${constPathRoot.pathRoot}/inbox/massiveFlow`:
        return t("main_massive_upload");
      case `${constPathRoot.pathRoot}/inbox/massiveDownload`:
        return t("main_massive_download");
      case `${constPathRoot.pathRoot}/inbox/reportsData`:
        return t(`${DataReport.Description}`);
      case `${constPathRoot.pathRoot}/inbox/userConfig`:
        return t(`user_config`);
    }
  };

  const Logout = () => {
    console.log("CIERRASESION");
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("tokenMc");
    localStorage.removeItem("versionApp");
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        margin: ".7em 1.5em 0 1.5em",
        height: IsMobile ? "auto" : "8vh",
      }}
    >
      <Box display="flex" alignItems={"center"} flexGrow={1}>
        {path == "/inbox/reportsData" && (
          <ArrowBackIcon
            style={{
              color: theme.palette.userStyle.textDetails,
              marginRight: ".6em",
            }}
            onClick={() => navigate(-1)}
          />
        )}
        {path.includes(paths.folio) || path.includes(paths.folioSearch) ? (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon
              style={{
                color: theme.palette.userStyle.textDetails,
                marginRight: ".6em",
              }}
              onClick={() => {
                if (path.includes(paths.folio)) {
                  return navigate(routes.home);
                } else if (path.includes(paths.folioSearch)) {
                  return navigate(routes.home);
                  // return navigate(routes.search)
                }
              }}
            />

              <Box>
                <Typography variant={IsMobile ? "h6" : "h4"}>
                  {stageData?.folio} - {stageData?.fullName}
                </Typography>
                <Typography variant="body1">{stageData?.currentStage}</Typography>
              </Box>

            {/*<Typography style={{ color: theme.palette.userStyle.textDetails, fontSize: '30px', fontWeight: 550 }}>{t("request_data_header")}</Typography>*/}
          </Box>
        ) : (
          ""
        )}
        <>
          {!IsMobile && (
            <Typography
              style={{
                color: theme.palette.userStyle.textDetails,
                fontSize: "30px",
                fontWeight: 550,
              }}
            >
              {setSectionName()}
            </Typography>
          )}
        </>
      </Box>
      <Hidden smDown>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <NotificationsView /> */}
          <Box display="flex" alignItems="center" gap={3}>
            {/* <IconButton onClick={() => navigate("/inbox/notifications")}>
              <Badge
                color="error"
                variant="dot"
                invisible={hideNotificationsDot}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Icon icon={"hugeicons:notification-02"} />
              </Badge>
            </IconButton> */}
            <SelectLanguage />
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
}
