import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Alert,
  CustomLoadingOverlay,
  GenericHeaderActions,
} from "../../components";
import { GenericTableSelection } from "../../components/GenericTableSelection";
import ReorderDocuments from "../../components/ReorderDocuments";
import { useDispatch, useSelector } from "react-redux";
import { documentActions, fileActions } from "../../store/actions";
import { documentRequests } from "../../services";
import { useTranslation } from "react-i18next";

const columns = [
  {
    Header: "Tipo de documento",
    accessor: "DocumentType_Name",
  },
  {
    Header: "Plantilla",
    accessor: "TemplateName",
  },
  {
    Header: "Acciones",
    accessor: "actions",
  },
];

export default function IndexHiringKits(props) {
  const dispatch = useDispatch();
  const documentsList = useSelector(
    (state) => state.GetDocumentGeneratorSelection
  );

  const [folioSelectedSignature, setFolioSelectedSignature] = useState([]);
  const [newOrdenDocs, setNewOrdenDocs] = useState([]);
  const [documentNewList, setDocumentNewList] = useState([]);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [newTableState, setNewTableState] = useState(false);
  const [loadingKit, setLoadingKit] = useState(false);
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId')
  const { t } = useTranslation();

  const reorderDocumentsTable = () => {
    if (newTableState === false) {
      setDocumentNewList(folioSelectedSignature);
      setNewTableState(!newTableState);
    } else {
      let newArrayA = dataDocuments.map((itemA) => ({
        ...itemA,
        Active: false,
        Sequence: null,
      }));

      const request = newArrayA.map((itemA) => {
        const foundInBIndex = newOrdenDocs.findIndex(
          (itemB) =>
            itemA.DocumentGeneratorSelection_Id ===
            itemB.DocumentGeneratorSelection_Id
        );
        const foundInB = foundInBIndex !== -1;
        return { ...itemA, Active: foundInB };
      });
      setDataDocuments(request);
      setFolioSelectedSignature([]);
      setNewTableState(!newTableState);
    }
  };

  useEffect(() => {
    setLoadingKit(true);
    dispatch(
      documentActions.GetDocumentGeneratorSelection({
        File_Id: props.dataStage.Id,
      })
    );
  }, []);

  const handleDataDocumentKit = async () => {
    setLoadingKit(true);
    let newArrayA = dataDocuments.map((itemA) => ({
      ...itemA,
      Active: false,
      Sequence: null,
    }));

    const request = newArrayA.map((itemA) => {
      const foundInBIndex = newOrdenDocs.findIndex(
        (itemB) =>
          itemA.DocumentGeneratorSelection_Id ===
          itemB.DocumentGeneratorSelection_Id
      );
      const foundInB = foundInBIndex !== -1;
      const Sequence = foundInB ? foundInBIndex + 1 : null;
      return {
        DocumentGeneratorSelection_Id: itemA.DocumentGeneratorSelection_Id,
        Sequence,
        Active: foundInB,
      };
    });
    console.log("newArra", request);
    const response = await documentRequests.RegisterDocumentGeneratorSelection({
      RegisterItems: request,
    });
    console.log("response", response);
    if (response.Body == true && response.IsOK == true) {
      console.log("ejecuta API");
      inicializacion();
    } else {
      setLoadingKit(false);
      Alert({
        icon: 0,
        title: response.Messages,
      }, null, null, t);
    }
  };

  const inicializacion = async () => {
    const response = await documentRequests.ProcessDocumentGeneratorSelection({
      File_Id: props.dataStage.Id,
    });
    if (response.Body?.Result == true && response.IsOK == true) {
      setLoadingKit(false);
      dispatch(fileActions.GetFileInfo({
        FileId: props.dataStage.Id,
        WorkFlowInstanceId: workFlowInstanceId
      }));
    } else {
      setLoadingKit(false);
      Alert({
        icon: 0,
        title: response.Messages,
      }, null, null, t);
    }
  };

  useEffect(() => {
    console.log("documentsList", documentsList);
    if (documentsList.items) {
      let docsActive = documentsList.items.Documents.filter(
        (doc) => doc.Active == true
      );
      console.log("docsActive", docsActive);
      setFolioSelectedSignature(docsActive);
      setDataDocuments(documentsList.items.Documents);
      setLoadingKit(false);
    } else if (documentsList.error) {
      setLoadingKit(false);
      Alert({
        icon: 0,
        title: documentsList.error,
      }, null, null, t);
    }
  }, [documentsList.items]);

  return (
    <Box>
      <CustomLoadingOverlay
        active={loadingKit}
        text={t("general_loading_message")}
      />
      <Grid container>
        <Grid item lg={8}>
          <GenericHeaderActions
            LabelTitle={t("document_hiring_kit_title")}
          />
          <Box>
            <Typography>
              {t("document_hiring_kit_text")}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              id="btnHiringKitPrevNext"
              fullWidth
              variant="contained"
              sx={{ height: 45, width: "70%" }}
              type="submit"
              disabled={!(folioSelectedSignature.length > 0)}
              onClick={() => reorderDocumentsTable()}
            >
              {newTableState ? t("general_return") : t("general_next")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item lg={12} sx={{ mt: 3 }}>
        {newTableState === false ? (
          <GenericTableSelection
            Columns={columns}
            Data={dataDocuments}
            onClickRow={() => { }}
            ShowPagination={false}
            ClickSelectedRows={(data) => {
              const array = data.map((item) => {
                return item.original;
              });
              setFolioSelectedSignature(array);
            }}
          />
        ) : (
          <ReorderDocuments
            newOrdenDocs={newOrdenDocs}
            setNewOrdenDocs={setNewOrdenDocs}
            data={documentNewList}
            setDocumentNewList={setDocumentNewList}
            onClickButton={handleDataDocumentKit}
            textButton={"Generar kit"}
          />
        )}
      </Grid>
    </Box>
  );
}
