import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Typography,
  IconButton as IconBut,
  Button,
  Box,
  Toolbar as Tool,
  AppBar,
  CircularProgress,
  useMediaQuery,
  useTheme,
  styled,
  Divider,
} from "@mui/material";
import {
  SkipNext,
  SkipPrevious,
  Remove,
  Add,
  Fullscreen,
  FullscreenExit,
} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import WrappedPage from './WrappedPage'
import { useTranslation } from "react-i18next";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  // [`& .${tooltipClasses.tooltip}`]: {
  backgroundColor: theme.palette.common.white,
  color: 'rgba(0, 0, 0, 0.87)',
  boxShadow: theme.shadows[1],
  fontSize: 11,
  // },
}));

const PdfContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isfullscreen",
})(({ height, isfullscreen, theme }) => ({
  width: "100%",
  height: height === undefined || height === null ? 690 : height,
  '@media only screen and (max-width: 620px) and (min-width: 5px)': {
    height: '100%',
    maxHeight: '500px',
  },
  // /* Para 800px */
  // '@media only screen and (max-width: 820px) and (min-width: 621px)': {
  //   height: '70vh',
  // },
  // /* Para 600px */
  // '@media only screen and (max-width: 620px) and (min-width: 501px)': {
  //   height: '54vh',
  // },
  // /* Para 480px */
  // '@media only screen and (max-width: 500px) and (min-width: 341px)': {
  //   height: '47vh',
  // },
  // /* Para 320px */
  // '@media only screen and (max-width: 340px) and (min-width: 301px)': {
  //   height: '32vh',
  // },
  // /* Para 300px */
  // '@media only screen and (max-width: 300px) and (min-width: 5px)': {
  //   height: '29vh',
  // },
  overflow: "hidden",
  position: "relative",
  borderBottom: '1px solid gray',
  "& .react-pdf__Document": {
    /* width: "100%", */
    backgroundColor: "#888",
    // minHeight: isfullscreen ? "100vh" : 40,
    height: '100%',
    '@media only screen and (max-width: 620px) and (min-width: 5px)': {
      height: '100%',
      maxHeight: '500px',
    },
    // /* Para 800px */
    // '@media only screen and (max-width: 820px) and (min-width: 621px)': {
    //   height: '60vh',
    // },
    // /* Para 600px */
    // '@media only screen and (max-width: 620px) and (min-width: 501px)': {
    //   height: '50vh',
    // },
    // /* Para 480px */
    // '@media only screen and (max-width: 500px) and (min-width: 341px)': {
    //   height: '47vh',
    // },
    // /* Para 320px */
    // '@media only screen and (max-width: 340px) and (min-width: 301px)': {
    //   height: '32vh',
    // },
    // /* Para 300px */
    // '@media only screen and (max-width: 300px) and (min-width: 5px)': {
    //   height: '29vh',
    // },
    display: "flex",
    flexDirection: "column",
    border: "0.5px solid gray",
  },
  "& .react-pdf__Page": {
    flexGrow: 1,
    display: "block",
    padding: "8px 8px",
  },
  "& .react-pdf__Page__annotations": {
    display: "none",
  },
  "& canvas": {
    padding: "10px 0px",
    /*  paddingRight: 90, */
    margin: "0 auto",
  },
  [theme.breakpoints.down("sm")]: {
    "& .react-pdf__Page": {
      padding: "5px 5px",
    },
    "& canvas": {
      // padding: "10px 0",
    },
  },
  // [theme.breakpoints.down("sm")]: {
  //   height: height === undefined || height === undefined ? 400 : height,
  // },
}));

const Toolbar = styled(Tool)(({ theme }) => ({
  minHeight: "auto !important",
  [theme.breakpoints.up("xs")]: {
    minHeight: "auto !important",
  },
}));
const IconButton = styled(IconBut)(({ theme }) => ({
  paddingLeft: 3,
  paddingRight: 3,
}));

const PageCustom = styled(Page)(({ theme }) => ({
  "& .react-pdf__Page__svg": {
    display: 'flex !important'
  }
}));

const SimpleBarCustom = styled(SimpleBar)(({ theme }) => ({
  ['@media only screen and (min-width: 812px)']: {
    "& .simplebar-track.simplebar-vertical": {
      width: '18px !important'
    },
    "& .simplebar-track.simplebar-horizontal": {
      height: '22px !important'
    },
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
      height: '18px !important'
    },
  },
}));

const TitleCustom = styled(Typography)(({ theme }) => ({
  width: '100% !important',
  maxHeight: '20px',
}));


const VisorPDF = ({pdfUrl, pdfName, isNativePdfVisor = false}) => {
  const { t } = useTranslation()
  const [isFullScreen, setisFullScreen] = useState(false);
  const [scale, setScale] = useState(2);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const pdfRef = useRef();

  const openFullscreen = () => {
    pdfRef.current.requestFullscreen();
  };

  const closeFullscreen = () => {
    /* pdfRef.current.exitFullscreen(); */
    document.exitFullscreen();
  };

  const zoomPlus = () => {
    if (scale <= 2) {
      setScale(scale + 0.1);
    }
  };
  const zoomMinus = () => {
    if (scale >= 0.1) {
      setScale(scale - 0.1);
    }
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  useEffect(() => {
    const setFullscreen = () => {
      setisFullScreen(!isFullScreen);
    };
    document.addEventListener("fullscreenchange", setFullscreen);
    return () =>
      document.removeEventListener("fullscreenchange", setFullscreen);
  });

  useEffect(() => {
    if (!isFullScreen && pdfRef.current) {
      // console.log("Entro IF VISOR**********", smScreen, xsScreen)
      if (smScreen && xsScreen) {
        setScale(0.7);
      } else if (smScreen) {
        setScale((pdfRef.current.clientWidth * 0.9) / 1000 + 0.5);
      } else if (xsScreen) {
        setScale((pdfRef.current.clientWidth * 0.9) / 1000);
      } else {
        setScale((pdfRef.current.clientWidth * 0.9) / 1000 + 0.5);
      }
    } else {
      // console.log("Entro else VISOR**********")
      setScale(1);
    }
  }, [isFullScreen, smScreen, xsScreen]);

  return (
    <Fragment>
      {/* {console.log("height={props.height}****: ", props.height)} */}
      {isNativePdfVisor ?
            <object
            width="100%"
            height="100%"
            data={pdfUrl}
            type="application/pdf"
          >
            {" "}
          </object>
      
      : <PdfContainer
        ref={(el) => (pdfRef.current = el)}
        isfullscreen={isFullScreen}
        // style={{ height: '100%', maxHeight: '600px' }}
      >
        <Document
          className="d-flex flex-column align-items-center"
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          // loading={<Spinner />}
          error={<Box></Box>}
          noData={<Box></Box>}
        >
          <AppBar position="sticky" sx={{ bgcolor: "#000", zIndex: 1000 }}>
            <Toolbar>
              {/* {true && ( */}
              <TitleCustom
                variant="subtitle2"
                component="div"
                sx={(theme) => ({
                  // maxWidth: 100,
                  flexGrow: 1,
                  fontSize: "14px !important",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "12px !important",
                  },
                })}
              >
                {/*Visor PDF*/}
                {pdfName ?? "Visor PDF"}
              </TitleCustom>
              {/* )} */}
              <Divider />
              <LightTooltip placement="top" title={t("pdf_viewer_zoom_in")}>
                <IconButton color="inherit" onClick={zoomPlus}
                  style={{
                    '&:hover': {
                      outline: 'none',
                    }
                  }}>
                  <Add sx={{ color: "#FFF  !important" }} fontSize="small" />
                </IconButton>
              </LightTooltip>
              <LightTooltip placement="top" title={t("pdf_viewer_zoom_out")}
                style={{
                  '&:hover': {
                    outline: 'none',
                  }
                }}>
                <IconButton color="inherit" onClick={zoomMinus}>
                  <Remove sx={{ color: "#FFF !important" }} fontSize="small" />
                </IconButton>
              </LightTooltip>
            </Toolbar>
          </AppBar>
          <SimpleBarCustom
            style={{ width: "100%", height: "100%", overflow: "auto" }}
          >
            {Array.from(
              new Array(numPages),
              (el, pageIndex) => (
                <div
                  style={{ position: 'relative' }}
                  key={pageIndex + '-div'}>
                  <WrappedPage
                    pageNumber={pageIndex + 1}
                    scale={scale}
                    key={pageIndex + '-page'}
                    originalWidth={pdfRef.current?.clientWidth}
                    originalHeight={pdfRef.current?.clientHeight}
                  />
                </div>
              ))}

            {/* {Array.from(
              new Array(numPages),
              (el, index) => {
                const isCurrentlyRendering = numPages === index + 1;  // true
                const isLastPage = numPages === index + 1; // false
                const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;  // true
                return (
                  <Page
                    key={`page_${index + 1}`}
                    onRenderSuccess={
                      needsCallbackToRenderNextPage ? this.onRenderSuccess : null
                    }
                    style={{ overflow: 'auto !important' }}
                    scale={scale}
                    loading={"Cargando"}
                    pageNumber={index + 1}
                  />
                );
              }
            )} */}
          </SimpleBarCustom>
        </Document>
      </PdfContainer> }

    </Fragment>
  );
};

// const Spinner = () => {
//   return (<span>Cargando...</span>);
// };

export default VisorPDF;