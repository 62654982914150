import { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableCell from "@mui/material/TableCell";
import {
  TableCellHead,
  TableRowCustom,
} from "./MainDocumentVersionsTable.styled";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
import "dayjs/locale/ja";
import { IsDevice } from "utils";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CustomTablePaginationActions } from "components/common/Table/Table";

const formats = {
  es: "[Cargado el] DD [de] MMMM [del] YYYY [a las] HH:mm",
  en: "[Loaded on] MMMM D, YYYY [at] HH:mm",
  pt: "[Carregado em] D [de] MMMM [de] YYYY [às] HH:mm:",
  ja: "YYYY年M月D日 HH:mm",
};

const formatDate = (inputDate) => {
  const locale = localStorage.getItem("lang");
  dayjs.locale(locale);
  const date = dayjs(inputDate);
  return date.format(formats[locale] || formats["en"]);
};

export const MainDocumentVersionsTable = ({ documentVersions, onRowClick }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");
  const { IsMobile } = IsDevice();

  const columns = [
    {
      id: "FileName",
      label: t("main_doc_versions_table_header_doc"),
      minWidth: IsMobile ? 50 : 250,
    },
    {
      id: "Version",
      label: t("main_doc_versions_table_header_version"),
      minWidth: IsMobile ? 50 : 250,
    },
    {
      id: "UploadedUser",
      label: t("main_doc_versions_table_header_name"),
      minWidth: IsMobile ? 50 : 250,
    },
  ];

  const rows = documentVersions.map((version) => ({
    ...version,
    FileName: `${version?.Sections[0]?.Name ?? ""}${
      version?.Sections[0]?.Extencion ?? ""
    }`,
    Version: version?.Version,
    UploadedUser: version.UploadedUser,
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  if (!documentVersions) return null;

  return (
    <>
      <TableContainer
        sx={{
          width: "100%",
          borderRadius: "1rem",
          border: "1px solid #CCCCCC",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => onRowClick(row)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          sx={{ border: "1px solid lightgrey" }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                          {column.id === "FileName" && (
                            <Box style={{ color: "gray" }}>
                              {formatDate(row?.CustomDatePerDocument)}
                            </Box>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={documentVersions?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        ActionsComponent={CustomTablePaginationActions}
      />
    </>
  );
};

