import React from 'react'

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, useTheme
} from '@mui/material';
import { useStyles } from './TabsStyle'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Circle';

export function RecordInfo({ data, dataValidationFile }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)

  return (
    <div>
      {data.map((group) => {
        // console.log("Data****************: ",group)
        return (
          <Accordion
            classes={{
              root: localStyles.accordion
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={localStyles.textGroup}><b>{ group.GroupName || group.WorkFlowDescription }</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableBody>
                    {group.MetadataList.map((row) => (
                      <TableRow>
                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingRight: 5
                        }} padding="none"
                          width="70%"
                        >
                          {getFormatText(row)}
                          {/* {!!row.MetadataApproved ? <b>{row.MetadataName}</b> : row.MetadataName} */}
                        </TableCell>
                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 2
                        }} padding="none"
                          width="30%"
                        >{row.MetadataValue === 'True'
                          ? <CheckCircleIcon style={{ color: "#28A745" }} />
                          : row.MetadataValue === 'False'
                            ? <CancelIcon style={{ color: "red" }} />
                            : row.MetadataValue === 'Seleccionar'
                              ? ''
                              : group.GroupName?.includes('facial')
                                && row.MetadataName?.includes('Similitud')
                                ? `${row.MetadataValue}`
                                : row.MetadataValue}</TableCell>

                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10
                        }} padding="none"
                          width="50%"
                        >{row.MetadataApproved === "1"
                          ? <CheckCircleIcon style={{ color: "#28A745" }} />
                          : row?.MetadataApproved === "0"
                            ? <CancelIcon style={{ color: "red" }} />
                            : null}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )
      })}

      {dataValidationFile.map((group) => {
        // console.log("Data****************: ",group)
        return (
          <Accordion
            classes={{
              root: localStyles.accordion
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={localStyles.textGroup}><b>{group.GroupName}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableBody>
                    {group.ValidationList.map((row) => (
                      <TableRow>
                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }} padding="none"
                          width="40%"
                        >{row.Name}</TableCell>

                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10
                        }} padding="none"
                          width="45%"
                        >{row.Value}</TableCell>

                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10
                        }} padding="none"
                          width="15%"
                        >{row.Result === 'True'
                          ? <CheckCircleIcon style={{ color: "#28A745" }} />
                          : row.Result === 'False'
                            ? <CancelIcon style={{ color: "red" }} />
                            : row.Result === 'true'
                              ? <CheckCircleIcon style={{ color: "#28A745" }} />
                              : row.Result === 'false'
                                ? <CancelIcon style={{ color: "red" }} />
                                : row.Result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

const getFormatText = (row) => {
  // console.log("--------........row.............--------------",row)
  // !!row.MetadataApproved ?  : row.MetadataName
  if (row.MetadataApproved == true) {
    return row.MetadataName;
  } else if (row.MetadataApproved == false) {
    return row.MetadataName;
  } else {
    return row.MetadataName;
  }
};
