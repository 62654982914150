import { Box } from "@mui/material";
import { RoundedButton } from "../Buttons";
import { ClearableTextField } from "../common/ClearableTextField";
import { useTranslation } from "react-i18next";
import DowloadSearch from "components/DowloadSearch";
import { useState } from "react";

export const BasicSearchFilter = ({
  formData,
  onInputChange,
  onInputClear,
  onSearch,
  onClearResults,
}) => {
  const { t } = useTranslation();
  const basicFilterSettings = {
    SearchType: "Basic",
    Search: formData.Search,
  };
console.log('formData.SearchFile:::',formData.SearchFile)
  const [searching, setSearching] = useState('')

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      gap={{ xs: ".3rem", md: "1rem" }}
    >
      <ClearableTextField
        name="Search"
        value={formData.SearchFile}
        onChange={(e)=>{setSearching(e.target.value); onInputChange(e)}}
        placeholder={t("search_basic_placeholder")}
        onClear={onInputClear}
        size={"small"}
      />
      <RoundedButton
        variant="contained"
        sx={{
          minWidth: "15%",
          height: "2.4rem",
          /* "&:hover": {
            backgroundColor: "red",
          }, */
        }}
        onClick={() => onSearch(basicFilterSettings)}
      >
        {t("general_search")}
      </RoundedButton>
      <RoundedButton
        variant="outlined"
        sx={{
          minWidth: "15%",
          height: "2.4rem",
          "&:hover": {
            backgroundColor: "white",
          },
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          paddingX: '3rem'
        }}
        onClick={onClearResults}
      >
        {t("search_clean")}
      </RoundedButton>
      <Box>
          <DowloadSearch searching={searching}/>
      </Box>
    </Box>
  );
};
